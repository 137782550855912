import { type ApiResponse, post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const createShiftChatChannelRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
});

const createPlacementChatChannelRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
  placementId: z.string(),
});

const createChatChannelResponseSchema = z.string();

export type CreateShiftChatChannelRequest = z.infer<typeof createShiftChatChannelRequestSchema>;
export type CreatePlacementChatChannelRequest = z.infer<
  typeof createPlacementChatChannelRequestSchema
>;
type CreateChatChannelRequest = CreateShiftChatChannelRequest | CreatePlacementChatChannelRequest;
type CreateChatChannelResponse = z.infer<typeof createChatChannelResponseSchema>;

export function useCreateChatChannel<T extends CreateChatChannelRequest>(
  options: UseMutationOptions<ApiResponse<CreateChatChannelResponse>, AxiosError, T> = {}
): UseMutationResult<ApiResponse<CreateChatChannelResponse>, AxiosError, T> {
  return useMutation({
    mutationFn: async (data: T) => {
      const requestSchema =
        "placementId" in data
          ? createPlacementChatChannelRequestSchema
          : createShiftChatChannelRequestSchema;

      return await post({
        url: "/chat/channels",
        data,
        requestSchema,
        responseSchema: createChatChannelResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_CREATE_CHAT_CHANNEL_FAILURE,
    },
    useErrorBoundary: false,
    ...options,
  });
}
